<template>
  <div class="error_area">
    <div id="snow">
      <div class="container">
        <div class="big_logo_wide">
          <a
            href="/"
            class="logo t_align_c full_width d_inline_b"
          >
            <img src="@/assets/images/logo_big.png" alt="" />
          </a>
        </div>
      </div>
      <div class="container">
        <div class="row clearfix">
          <!--products-->
          <div class="pagenot relative full_width">
            <h2 class="scheme_color">404</h2>
            <div class="pagenot-text">
              <!-- <h3 class="fill">Oops... Pagina nu exista!</h3> -->
            </div>
            <h4 class="fill">Se face frig...</h4>
            <br />
            <div>
              <a
                href="/"
                class="button_type_3 color_light bg_scheme_color r_corners tr_delay_hover box_s_none"
                >Bagă pă Pefoc.ro!</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">All right reserved &copy;</div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
/*404 error*/
.error_area {
  background-color: rgba(0, 0, 0, 0.8);
  color: #a94442;
}

body.error {
  background-color: rgba(0, 0, 0, 0.8);
}
#snow {
  background: none;
  background-image: url("~@/assets/images/snow.png"), url("~@/assets/images/snow2.png"),
    url("~@/assets/images/snow3.png");
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
  -webkit-animation: snow 20s linear infinite;
  -moz-animation: snow 20s linear infinite;
  -ms-animation: snow 20s linear infinite;
  animation: snow 20s linear infinite;
}
@keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  100% {
    background-position: 500px 1000px, 400px 400px, 300px 300px;
  }
}

@-moz-keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  100% {
    background-position: 500px 1000px, 400px 400px, 300px 300px;
  }
}

@-webkit-keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  50% {
    background-color: #b4cfe0;
  }
  100% {
    background-position: 500px 1000px, 400px 400px, 300px 300px;
    background-color: #6b92b9;
  }
}

@-ms-keyframes snow {
  0% {
    background-position: 0px 0px, 0px 0px, 0px 0px;
  }
  100% {
    background-position: 500px 1000px, 400px 400px, 300px 300px;
  }
}

.error_area .copyright {
  position: fixed;
  bottom: 0px;
  width: 100%;
}
.pagenot h2 {
  font-size: 15em;
  font-weight: bold;
  padding: 0;
  width: 420px;
  margin: 20px auto;
  /* background-color: #fff; */
  font-family: Source Sans Pro, sans-serif;
  color: #990000;
}

.pagenot.relative.full_width {
  text-align: center;
  width: 100%;
}

.button_type_3 {
  padding: 9px 16px 8px;
}

a.button_type_3 {
  position: relative;
  /* -webkit-box-shadow: 0 2px 0 #bdc3c7; */
  -moz-box-shadow: 0 2px 0 #bdc3c7;
  -o-box-shadow: 0 2px 0 #bdc3c7;
  -ms-box-shadow: 0 2px 0 #bdc3c7;
  /* box-shadow: 0 2px 0 #bdc3c7; */
  color: #fff;
  background-color: #990000;
  border-radius: 5px;
}

.big_logo_wide {
  text-align: center;
}
#snow .fill {
  color: #fff;
  font-size: 3em;
}

.error_area {
  min-height: 100vh;
}

.logo img {
  max-height: 40vh;
}

@media (max-width: 1200px) {
  .pagenot h2 {
    font-size: 12em;
  }

  #snow .fill {
    font-size: 2em;
  }
}

@media (max-width: 768px) {
  .pagenot h2 {
    font-size: 11em;
  }

  #snow .fill {
    font-size: 1.5em;
  }

  /* .pagenot h2 {
    width: 100%;
  } */
}

@media (max-width: 576px) {
  .logo img {
    max-height: 25vh;
  }
  .pagenot h2 {
    width: 100%;
  }

  .pagenot h2 {
    font-size: 7em;
  }
}
</style>
